<template>
  <div class="register-container">
    <div class="banner">
      <div class="banner_img">
        <h2>{{ $t("L_REGISTER.BANNER_0001") }}</h2>
      </div>
    </div>
    <div class="register-form">
      <el-form ref="registerForm" :model="registerForm" :rules="rules">
        <p class="tip">
          {{ $t("L_REGISTER.TIP_0001") }}
          <router-link to="/login" tag="a">{{
            $t("L_REGISTER.TIP_0002")
          }}</router-link>
        </p>
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="username" ref="username" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.username"
            :placeholder="$t('L_REGISTER.FORM_0001')"
            @focus="handleFocus('username')"
            @blur="handleBlur('username')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip1">
            {{ $t("L_REGISTER.TIP_0003") }}
          </div>
          <div class="server_tip" v-if="isServerTip1">
            <div>{{ $t("L_REGISTER.TIP_0005") }}</div>
            <div>{{ $t("L_REGISTER.TIP_0006") }}</div>
            <div>
              {{ $t("L_REGISTER.TIP_0007")
              }}<router-link to="/login" tag="a">{{
                $t("L_REGISTER.TIP_0008")
              }}</router-link>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="compname" ref="compname" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.compname"
            :placeholder="$t('L_REGISTER.FORM_0002')"
            @focus="handleFocus('compname')"
            @blur="handleBlur('compname')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip2">
            {{ $t("L_REGISTER.TIP_0009") }}
          </div>
        </el-form-item>
        <el-form-item prop="name" ref="name" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.name"
            :placeholder="$t('L_REGISTER.FORM_0003')"
            @focus="handleFocus('name')"
            @blur="handleBlur('name')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip3">
            {{ $t("L_REGISTER.TIP_0011") }}
          </div>
        </el-form-item>
        <el-form-item prop="industry" label="用户名">
          <el-select v-model="registerForm.industry">
            <el-option
              v-for="(item, index) in hyOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="email" ref="email" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.email"
            :placeholder="$t('L_REGISTER.FORM_0005')"
            @focus="handleFocus('email')"
            @blur="handleBlur('email')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip5">
            {{ $t("L_REGISTER.TIP_0017") }}
          </div>
          <div class="server_tip" v-if="isServerTip3">
            {{ $t("L_REGISTER.TIP_0019") }}
          </div>
        </el-form-item>
        <el-form-item prop="password" ref="password" label="用户名">
          <el-input
            type="password"
            v-model="registerForm.password"
            auto-complete="new-password"
            :placeholder="$t('L_REGISTER.FORM_0006')"
            @focus="handleFocus('password')"
            @blur="handleBlur('password')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip6">
            {{ $t("L_REGISTER.TIP_0021") }}
          </div>
        </el-form-item>
        <el-form-item prop="confirmPassword" ref="confirmPassword" label="用户名">
          <el-input
            type="password"
            v-model="registerForm.confirmPassword"
            auto-complete="confirm-password"
            :placeholder="$t('L_REGISTER.FORM_0007')"
            @focus="handleFocus('confirmPassword')"
            @blur="handleBlur('confirmPassword')"
          ></el-input>
          <div class="form_tip" v-if="isFormTip7">
            {{ $t("L_REGISTER.TIP_0022") }}
          </div>
        </el-form-item>
        <el-form-item prop="phone" ref="phone" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.phone"
            :placeholder="$t('L_REGISTER.FORM_0004')"
            @focus="handleFocus('phone')"
            @blur="handleBlur('phone')"
            auto-complete="off"
          ></el-input>
          <div class="form_tip" v-if="isFormTip4">
            {{ $t("L_REGISTER.TIP_0014") }}
          </div>
          <div class="server_tip" v-if="isServerTip2">
            {{ $t("L_REGISTER.TIP_0016") }}
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode" class="btn" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.verificationCode"
            :placeholder="$t('L_REGISTER.FORM_0008')"
            auto-complete="off"
          >
            <template slot="append">
              <el-button
                v-if="registerForm.showloginCode"
                type="primary"
                @click="getloginPhoneCode"
                >{{ $t("L_LOGIN.BUTTON_0005") }}</el-button
              >
              <el-button v-else type="primary"
                >{{ registerForm.count }}s</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="accept"
          class="agree-item"
          :class="currentLang === 'en' ? 'agree-item-e' : ''"
        >
          <el-checkbox v-model="registerForm.accept"
            >{{ $t("L_REGISTER.TIP_0026") }}
            <router-link to="/protocol" tag="a" target="_blank">{{
              $t("L_REGISTER.TIP_0027")
            }}</router-link>
          </el-checkbox>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="registering"
            @click.native.prevent="handleSubmit"
            class="btn"
            >{{ $t("L_REGISTER.BUTTON_0001") }}</el-button
          >
          <!--        <div class="but_tip">-->
          <!--          {{ $t("L_REGISTER.TIP_0029") }}-->
          <!--        </div>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { sendMessageCode, registerByPhone } from "@/api/register";
export default {
  name: "register",
  data() {
    let validateUserName = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9\u4E00-\u9FA5_]{4,20}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0001")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0004")));
        } else {
          return callback();
        }
      }
    };
    let validateCompname = (rule, value, callback) => {
      let reg = /^.{1,40}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0002")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0010")));
        } else {
          return callback();
        }
      }
    };
    let validateName = (rule, value, callback) => {
      let reg = /^.{2,30}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0003")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0012")));
        } else {
          return callback();
        }
      }
    };
    let validateEmail = (rule, value, callback) => {
      let reg =
        /^[A-Za-z\d]+([_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0005")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0018")));
        } else {
          return callback();
        }
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0006")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0020")));
        } else {
          return callback();
        }
      }
    };
    let validateConfirmPassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0007")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0020")));
        } else if (value !== this.registerForm.password) {
          callback(new Error(this.$t("L_REGISTER.TIP_0023")));
        } else {
          return callback();
        }
      }
    };
    let validatePhone = (rule, value, callback) => {
      let reg = /^((0\d{2,3}\d{7,8})|(1[358479]\d{9}))$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0004")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0015")));
        } else {
          return callback();
        }
      }
    };
    let validateCode = (rule, value, callback) => {
      let reg = /^\d{6}$/;
      if(value === "") {
        callback(new Error(this.$t("L_REGISTER.FORM_0008")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_REGISTER.TIP_0025")));
        } else {
          return callback();
        }
      }
    };
    let validateAccept = (rule, value, callback) => {
      if (value === false) {
        callback(new Error(this.$t("L_REGISTER.TIP_0028")));
      } else {
        callback();
      }
    };
    return {
      // 表单参数
      registerForm: {
        username: "",
        compname: "",
        name: "",
        industry: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        showloginCode: true,
        count: 60,
        verificationCode: "", //表单中展示的验证码
        timer: null,
        accept: false,
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        compname: [
          {
            required: true,
            validator: validateCompname,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, validator: validateCode, trigger: "blur" },
        ],
        accept: [
          { required: true, validator: validateAccept, trigger: "change" },
        ],
        industry: [
          { required: true, trigger: "change" },
        ],
      },
      registering: false,
      isFormTip1: false,
      isFormTip2: false,
      isFormTip3: false,
      isFormTip4: false,
      isFormTip5: false,
      isFormTip6: false,
      isFormTip7: false,
      isFormTip8: false,
      isFormTip9: false,
      isServerTip1: false,
      isServerTip2: false,
      isServerTip3: false,
      industry_domainInfoBeanList: [
        {
          attribute: {},
          code: "01",
          codevalue: "农、林、牧、渔业",
          domainname: "INDUSTRY",
          eventid: "efdf1528-857f-4f4f-8d79-409b71949c61",
          parenteventid: "",
          remark: "",
          sort: 0,
        },
        {
          attribute: {},
          code: "02",
          codevalue: "采矿业",
          domainname: "INDUSTRY",
          eventid: "e2887bd2-4d7a-47a7-bced-32e4ae063a67",
          parenteventid: "",
          remark: "",
          sort: 1,
        },
        {
          attribute: {},
          code: "03",
          codevalue: "制造业",
          domainname: "INDUSTRY",
          eventid: "3ead50bb-821c-4c08-9912-f4ea956f6c12",
          parenteventid: "",
          remark: "",
          sort: 2,
        },
        {
          attribute: {},
          code: "04",
          codevalue: "电力、热力、燃气及水生产和供应业",
          domainname: "INDUSTRY",
          eventid: "9e2d1784-2d7b-4c51-9e3d-337fe17e55b2",
          parenteventid: "",
          remark: "",
          sort: 3,
        },
        {
          attribute: {},
          code: "05",
          codevalue: "建筑业",
          domainname: "INDUSTRY",
          eventid: "75044922-d169-4361-8000-2711a3e2ba1e",
          parenteventid: "",
          remark: "",
          sort: 4,
        },
        {
          attribute: {},
          code: "06",
          codevalue: "批发和零售业",
          domainname: "INDUSTRY",
          eventid: "c618d987-9303-49b6-975c-f0c74c6b06fd",
          parenteventid: "",
          remark: "",
          sort: 5,
        },
        {
          attribute: {},
          code: "07",
          codevalue: "交通运输、仓储和邮政业",
          domainname: "INDUSTRY",
          eventid: "b3ed38f3-8f65-48f3-a5b2-0b6241377f7f",
          parenteventid: "",
          remark: "",
          sort: 6,
        },
        {
          attribute: {},
          code: "08",
          codevalue: "住宿和餐饮业",
          domainname: "INDUSTRY",
          eventid: "0e2ae680-3034-4598-a392-cec5f1fd7092",
          parenteventid: "",
          remark: "",
          sort: 7,
        },
        {
          attribute: {},
          code: "09",
          codevalue: "信息传输、软件和信息技术服务业",
          domainname: "INDUSTRY",
          eventid: "299765bf-bb37-47ac-9872-025494583d10",
          parenteventid: "",
          remark: "",
          sort: 8,
        },
        {
          attribute: {},
          code: "10",
          codevalue: "金融业",
          domainname: "INDUSTRY",
          eventid: "9aabf504-1ecc-45ba-ab7e-8ddeefcb681c",
          parenteventid: "",
          remark: "",
          sort: 9,
        },
        {
          attribute: {},
          code: "11",
          codevalue: "房地产业",
          domainname: "INDUSTRY",
          eventid: "0541a7a7-4b71-47db-b3d5-6dd6d1359aae",
          parenteventid: "",
          remark: "",
          sort: 10,
        },
        {
          attribute: {},
          code: "12",
          codevalue: "租赁和商务服务业",
          domainname: "INDUSTRY",
          eventid: "e779d6cd-9869-4d7f-91c1-f87afff384d3",
          parenteventid: "",
          remark: "",
          sort: 11,
        },
        {
          attribute: {},
          code: "13",
          codevalue: "科学研究和技术服务业",
          domainname: "INDUSTRY",
          eventid: "12beaf41-0ce7-4750-b706-9dc8a7fc2e97",
          parenteventid: "",
          remark: "",
          sort: 12,
        },
        {
          attribute: {},
          code: "14",
          codevalue: "水利、环境和公共设施管理业",
          domainname: "INDUSTRY",
          eventid: "9263eab7-29cb-452f-9f21-2c714f74cd95",
          parenteventid: "",
          remark: "",
          sort: 13,
        },
        {
          attribute: {},
          code: "15",
          codevalue: "居民服务、修理和其他服务业",
          domainname: "INDUSTRY",
          eventid: "0381d56b-0a54-47dd-b6d1-371181bd3941",
          parenteventid: "",
          remark: "",
          sort: 14,
        },
        {
          attribute: {},
          code: "16",
          codevalue: "教育",
          domainname: "INDUSTRY",
          eventid: "92d20473-6b1c-4154-8f24-192c80ca9f24",
          parenteventid: "",
          remark: "",
          sort: 15,
        },
        {
          attribute: {},
          code: "17",
          codevalue: "卫生和社会工作",
          domainname: "INDUSTRY",
          eventid: "17d7e7b2-824d-4f2d-8996-8ded39a95afa",
          parenteventid: "",
          remark: "",
          sort: 16,
        },
        {
          attribute: {},
          code: "18",
          codevalue: "文化、体育和娱乐业",
          domainname: "INDUSTRY",
          eventid: "ef466756-fb90-4837-af86-143bec0533e8",
          parenteventid: "",
          remark: "",
          sort: 17,
        },
        {
          attribute: {},
          code: "19",
          codevalue: "公共管理、社会保障和社会组织",
          domainname: "INDUSTRY",
          eventid: "b44868bd-13e7-4f42-9737-b2f6ebf65990",
          parenteventid: "",
          remark: "",
          sort: 18,
        },
        {
          attribute: {},
          code: "20",
          codevalue: "国际组织",
          domainname: "INDUSTRY",
          eventid: "8611e0a8-7582-4b69-a9b2-0b8751cb47b0",
          parenteventid: "",
          remark: "",
          sort: 19,
        },
      ],
      industry_domainInfoBeanList_en: [
        {
          attribute: {},
          code: "01",
          codevalue: "Agriculture, forestry, animal husbandry and fishery",
          domainname: "INDUSTRYEN",
          eventid: "ec58caff-1d7b-4e0f-ba78-6bf5c7027098",
          parenteventid: "",
          remark: "",
          sort: 0,
        },
        {
          attribute: {},
          code: "02",
          codevalue: "Mining industry",
          domainname: "INDUSTRYEN",
          eventid: "914677d0-91f5-496e-87ac-18a88eb6259f",
          parenteventid: "",
          remark: "",
          sort: 1,
        },
        {
          attribute: {},
          code: "03",
          codevalue: "Manufacturing",
          domainname: "INDUSTRYEN",
          eventid: "9ffca233-91ff-4763-9c72-80395ef4be2a",
          parenteventid: "",
          remark: "",
          sort: 2,
        },
        {
          attribute: {},
          code: "04",
          codevalue: "Electricity, heat, gas and water production and supply",
          domainname: "INDUSTRYEN",
          eventid: "7ccb4b89-9765-4e15-827a-a1735b40196b",
          parenteventid: "",
          remark: "",
          sort: 3,
        },
        {
          attribute: {},
          code: "05",
          codevalue: "Construction industry",
          domainname: "INDUSTRYEN",
          eventid: "8cde916c-17d3-4f4c-ac90-abbbbeb6ec8c",
          parenteventid: "",
          remark: "",
          sort: 4,
        },
        {
          attribute: {},
          code: "06",
          codevalue: "Wholesale and retail trade",
          domainname: "INDUSTRYEN",
          eventid: "de6c620b-00c8-4928-8ff0-15000460177b",
          parenteventid: "",
          remark: "",
          sort: 5,
        },
        {
          attribute: {},
          code: "07",
          codevalue: "Transportation, warehousing and postal services",
          domainname: "INDUSTRYEN",
          eventid: "ce0d6218-95db-42a2-b9bc-d27cf0cef458",
          parenteventid: "",
          remark: "",
          sort: 6,
        },
        {
          attribute: {},
          code: "08",
          codevalue: "Accommodation and catering industry",
          domainname: "INDUSTRYEN",
          eventid: "d3381d99-b86b-492d-81a9-b30ed6c257e7",
          parenteventid: "",
          remark: "",
          sort: 7,
        },
        {
          attribute: {},
          code: "09",
          codevalue:
            "Information transmission, software and information technology services",
          domainname: "INDUSTRYEN",
          eventid: "283af7e1-da0b-4177-90a5-0edd8f9c8e9d",
          parenteventid: "",
          remark: "",
          sort: 8,
        },
        {
          attribute: {},
          code: "10",
          codevalue: "Financial industry",
          domainname: "INDUSTRYEN",
          eventid: "8da36611-88d5-4314-bf1b-28b63fb1d06c",
          parenteventid: "",
          remark: "",
          sort: 9,
        },
        {
          attribute: {},
          code: "11",
          codevalue: "Real estate industry",
          domainname: "INDUSTRYEN",
          eventid: "0c890d30-3bd9-4246-a669-23bc6305029c",
          parenteventid: "",
          remark: "",
          sort: 10,
        },
        {
          attribute: {},
          code: "12",
          codevalue: "Leasing and business services",
          domainname: "INDUSTRYEN",
          eventid: "b2f81cc0-240e-43e1-a6c7-d84a519cf255",
          parenteventid: "",
          remark: "",
          sort: 11,
        },
        {
          attribute: {},
          code: "13",
          codevalue: "Scientific research and technical services",
          domainname: "INDUSTRYEN",
          eventid: "6263345a-57d4-4af1-8782-4006adb9e63f",
          parenteventid: "",
          remark: "",
          sort: 12,
        },
        {
          attribute: {},
          code: "14",
          codevalue: "Water, environmental and public facilities management",
          domainname: "INDUSTRYEN",
          eventid: "447a5fbf-40b7-4386-8731-b9425784089b",
          parenteventid: "",
          remark: "",
          sort: 13,
        },
        {
          attribute: {},
          code: "15",
          codevalue: "Resident services, repairs and other services",
          domainname: "INDUSTRYEN",
          eventid: "7adc53aa-fbc3-4ba2-838e-a1f92c488c55",
          parenteventid: "",
          remark: "",
          sort: 14,
        },
        {
          attribute: {},
          code: "16",
          codevalue: "Education",
          domainname: "INDUSTRYEN",
          eventid: "d1079be6-64f1-42b7-a925-efd610b1408e",
          parenteventid: "",
          remark: "",
          sort: 15,
        },
        {
          attribute: {},
          code: "17",
          codevalue: "Health and social work",
          domainname: "INDUSTRYEN",
          eventid: "da040c44-ca59-42aa-8243-8d494329de88",
          parenteventid: "",
          remark: "",
          sort: 16,
        },
        {
          attribute: {},
          code: "18",
          codevalue: "Culture, sports and entertainment",
          domainname: "INDUSTRYEN",
          eventid: "f2ed0f70-c2f2-4ddd-9eb7-b4879bc729a0",
          parenteventid: "",
          remark: "",
          sort: 17,
        },
        {
          attribute: {},
          code: "19",
          codevalue:
            "Public administration, social security and social organization",
          domainname: "INDUSTRYEN",
          eventid: "0dfabf51-bc5f-4eda-9592-f52d2e97beff",
          parenteventid: "",
          remark: "",
          sort: 18,
        },
        {
          attribute: {},
          code: "20",
          codevalue: "International organizations",
          domainname: "INDUSTRYEN",
          eventid: "4a5677a7-f2a5-4b61-940f-9c04d3b2b969",
          parenteventid: "",
          remark: "",
          sort: 19,
        },
      ],
    };
  },
  computed: {
    currentLang() {
      let language = this.$i18n.locale;
      return language;
    },
    hyOptions() {
      let language = this.currentLang;
      let tempOptions = [];
      let tempArr = [];
      if (language === "en") {
        tempOptions = this.industry_domainInfoBeanList_en;
      } else {
        tempOptions = this.industry_domainInfoBeanList;
      }
      if (tempOptions.length) {
        tempOptions.forEach((item) => {
          tempArr.push({
            value: item.code,
            label: item.codevalue,
          });
        });
        this.registerForm.industry = tempArr[0].value;
      }
      return tempArr;
    },
  },
  methods: {
    handleFocus(param) {
      this.$refs["registerForm"].clearValidate([param]);
      if (param === "username") {
        this.isFormTip1 = true;
        this.isServerTip1 = false;
        this.removeServerErr("username")
      } else if (param === "compname") {
        this.isFormTip2 = true;
      } else if (param === "name") {
        this.isFormTip3 = true;
      } else if (param === "phone") {
        this.isFormTip4 = true;
        this.isServerTip2 = false;
        this.removeServerErr("phone")
      } else if (param === "email") {
        this.isFormTip5 = true;
        this.isServerTip3 = false;
        this.removeServerErr("email")
      } else if (param === "password") {
        this.isFormTip6 = true;
      } else if (param === "confirmPassword") {
        this.isFormTip7 = true;
      } else if (param === "verificationCode") {
        this.isFormTip8 = true;
      }
    },
    handleBlur(param) {
      if (param === "username") {
        this.isFormTip1 = false;
      } else if (param === "compname") {
        this.isFormTip2 = false;
      } else if (param === "name") {
        this.isFormTip3 = false;
      } else if (param === "phone") {
        this.isFormTip4 = false;
      } else if (param === "email") {
        this.isFormTip5 = false;
      } else if (param === "password") {
        this.isFormTip6 = false;
      } else if (param === "confirmPassword") {
        this.isFormTip7 = false;
      } else if (param === "verificationCode") {
        this.isFormTip8 = false;
      }
    },
    // 登录
    handleSubmit() {
      this.registering = true;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          let param = {
            username: this.registerForm.username,
            compname: this.registerForm.compname,
            name: this.registerForm.name,
            industry: this.registerForm.industry,
            email: this.registerForm.email,
            password: this.registerForm.password,
            phone: this.registerForm.phone,
          }
          let verifyCode = this.registerForm.verificationCode
          registerByPhone(verifyCode, param).then((res) => {
            if (res.code === 200) {
              this.registering = false;
              this.$message({
                message: this.$t("L_REGISTER.TIP_0030"),
                type: "success",
                offset: 100,
                duration: 5000
              });
              this.resetForm("registerForm");
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 1500);
            } else {
              this.registering = false;
              if(res.msg) {
                let msg = res.msg
                if(msg.includes("该用户名已被注册")) {
                  this.removeServerErr("email")
                  this.removeServerErr("phone")
                  this.moveToServerErr("username")
                  this.isServerTip1 = true
                } else if (msg.includes("该邮箱已被注册")) {
                  this.removeServerErr("username")
                  this.removeServerErr("phone")
                  this.moveToServerErr("email")
                  this.isServerTip3 = true
                } else if (msg.includes("该手机号已被注册")) {
                  this.removeServerErr("username")
                  this.removeServerErr("email")
                  this.moveToServerErr("phone")
                  this.isServerTip2 = true
                } else {
                  this.$message({
                    message: msg,
                    type: "error",
                    offset: 100
                  });
                  this.resetForm("registerForm");
                }
              } else {
                this.$message({
                  message: this.$t("L_REGISTER.TIP_0029"),
                  type: "error",
                  offset: 100
                });
                this.resetForm("registerForm");
              }
              this.resetRegisterForm();
              this.registerForm.verificationCode = ""
            }
          });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.registering = false;
          this.resetRegisterForm();
          this.registerForm.verificationCode = ""
        }
      });
    },
    moveToServerErr(field) {
      let isError = this.$refs[field];
      let el = isError.$el
      let classAtr = el.getAttribute("class");
      let newClass = classAtr.concat("  is-error");	//注意这里有空格哟
      el.setAttribute("class",newClass);
      el.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      // 这个当滑动到报错项之后自动获取输入框的焦点，方便用户直接进行输入，延迟 800ms 是因为需要都能到定位成功后在进行获取焦点体验更好一些
      setTimeout(() => {
        if (el.previousElementSibling !== null) {
          if (el.previousElementSibling.querySelector("input")) {
            el.previousElementSibling.querySelector("input").focus();
          }
        } else {
          if (el.querySelector("input")) {
            el.querySelector("input").focus();
          }
        }
      }, 800);
    },
    removeServerErr(field) {
      let isError = this.$refs[field];
      let el = isError.$el;
      let classAtr = el.getAttribute("class");
      let newClass = classAtr.replace("is-error","");
      el.setAttribute("class",newClass);
    },
    // 获取手机验证码
    getloginPhoneCode() {
      this.$refs.registerForm.validateField("phone", async (valid) => {
        if (!valid) {
          // 获取验证码
          sendMessageCode({ phone: this.registerForm.phone })
            .then((res) => {
              if (res.code !== 200) {
                if (this.language === "en") {
                  return this.$message({
                    message: "Operation failed, please try again later!",
                    type: "error",
                    offset: 100
                  });
                } else {
                  return this.$message({
                    message: "验证码发送失败！",
                    type: "error",
                    offset: 100
                  });
                }
              } else {
                // 当验证码发送成功，开始60秒倒计时
                this.resetRegisterForm()
                this.registerForm.showloginCode = false
                this.registerForm.timer = setInterval(() => {
                  this.registerForm.count--;
                  if (this.registerForm.count <= 0) {
                    this.resetRegisterForm();
                  }
                }, 1000);
              }
            })
            .catch(() => {
              if (this.language === "en") {
                return this.$message({
                  message: "Operation failed, please try again later!",
                  type: "error",
                  offset: 100
                });
              } else {
                return this.$message({
                  message: "验证码发送失败！",
                  type: "error",
                  offset: 100
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    resetRegisterForm() {
      if (this.registerForm.timer !== null) {
        clearInterval(this.registerForm.timer);
        this.registerForm.timer = null;
      }
      this.registerForm.showloginCode = true;
      this.registerForm.count = 60;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  beforeDestroy() {
    clearInterval(this.registerForm.timer);
    this.registerForm.timer = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/register";
</style>